<template>
   <div class="card text-center cursor-pointer mb-0" @click="removeArquivoSolicitacao(index)">
      <div class="card-body pt-10 pb-10 px-12">
         <i class="far fa-trash text-danger" v-if="anexo.size"></i>
         <i class="far fa-external-link color-theme" v-else-if="anexo.url"></i>
         <small class="limitador text-center font-13">{{ anexo.name == null ? anexo.nome : anexo.name }}</small>
         <small class="limitador text-center d-block font-12" v-if="anexo.size">{{ (anexo.size / 1048576) >= 1 ? (anexo.size / 1048576).toFixed(1) +"MB" : (anexo.size / 1024).toFixed(1) +"KB" }}</small>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Anexo',
   props: ['anexo', 'index'],
   methods: {
      removeArquivoSolicitacao : function () {
         if (this.anexo.size) {
            this.$emit('remover', this.index)
         } else if (this.anexo.url) {
            window.open(this.anexo.url)
         }
      }
   }
}

</script>

<style scoped>

* {
   color: #666;
}

</style>