<template>
   <li v-if="view == 'Linha-Dividida-Adicionar' || view == 'Linha-Dividida-Remover'">
      <div class="card solicitacao">
         <div class="card-body" :style="view == 'Linha-Dividida-Remover' && produto.tipoProblema != null ? 'padding: 4.25px 8px !important;' : 'p-2'">
            <div class="row m-0 text-center justify-content-center align-items-center font-13">
               <div class="wpx-100 px-1 d-none d-xl-block">
                  <span class="limitador">{{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</span>
               </div>
               <div class="col-lg px-1">
                  <span class="limitador text-capitalize">{{ produto.nome == null ? '' : produto.nome }}</span>
                  <span class="limitador lh-1 font-11 mb-1" v-if="view == 'Linha-Dividida-Remover' && produto.tipoProblema != null">
                     <span>{{ produto.tipoProblema }}</span>
                     <span v-if="produto.tipoProblema == 'Quantidade Divergente'"> (x{{ produto.quantidade }})</span>
                     <span v-if="produto.codigo2 != null" class="mx-1">-</span>
                     <span v-if="produto.codigo2 != null"> Peça {{ String(produto.codigo2).substring(0, 2) +"-"+ String(produto.codigo2).substring(2, String(produto.codigo2).length) }}</span>
                  </span>
               </div>
               <div class="wpx-75 px-1 text-center text-lg-end mt-2 mt-lg-0">
                  <button class="btn text-white font-13" @click="selecionar" v-if="view == 'Linha-Dividida-Adicionar'" :class="produtoSelecionado == produto.codigo ? 'bg-success' : 'bg-theme'">
                     <i class="far" :class="String(produtoSelecionado).trim() != '' ? produtoSelecionado == produto.codigo ? 'fa-check' : 'fa-exchange' : 'fa-plus'"></i>
                  </button>
                  <button class="btn bg-danger text-white font-13" @click="remover" v-else-if="view == 'Linha-Dividida-Remover'"><i class="far fa-trash"></i></button>
               </div>
            </div>
         </div>
      </div>
   </li>

   <li v-else-if="view == 'Linha-Inteira-Remover' || view == 'Linha-Inteira'">
      <div class="card">
         <div class="card-body">
            <div class="row text-center justify-content-between align-items-center">
               <div class="wpx-75 w-img d-none d-lg-block">
                  <img :src="produto.foto == null ? '' : produto.foto" alt="produto" @error="imageError">
               </div>
               <div class="col-sm mb-3 mb-sm-0 text-start">
                  <h6 class="limitador-2 text-dark font-13">{{ produto.nome }}</h6>
                  <div class="cart__desc weight-400 limitador-1 mb-0">
                     <span>Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</span>
                     <span v-if="produto.tipoProblema != null" class="mx-1">-</span>
                     <span v-if="produto.tipoProblema != null">{{ produto.tipoProblema }}</span>
                     <span v-if="produto.tipoProblema == 'Quantidade Divergente'"> (x{{ produto.quantidade }})</span>
                     <span v-if="produto.codigo2 != null" class="mx-1">-</span>
                     <span v-if="produto.codigo2 != null"> Peça {{ String(produto.codigo2).substring(0, 2) +"-"+ String(produto.codigo2).substring(2, String(produto.codigo2).length) }}</span>
                  </div>
               </div>
               <div class="wpx-75 px-1 mx-auto mx-sm-none" v-if="view == 'Linha-Inteira-Remover'">
                  <a href="javascript:;" @click="remover"><i class="far fa-trash font-15 text-danger ms-3"></i></a>
               </div>
               <div class="wpx-75 px-1 mx-auto mx-sm-none" v-else-if="view == 'Linha-Inteira'">
                  <a :href="produto.anexoSerie" target="_blank" v-if="produto.anexoSerie != null"><i class="far fa-paperclip font-15 ms-3"></i></a>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

export default {
   name: 'Carrinho',
   props: ['produto', 'index', 'view', 'produtoSelecionado'],
   data : function () {
      return {
         timeout: null
      }
   },
   methods: {
      remover: function () {
         this.$emit('remover', this.index)
      },
      selecionar: function () {
         this.$emit('select', this.produto)
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 1 / 1;
	object-fit: cover;
}

.card:not(.solicitacao) {
   font-size: 13.5px;
}

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
   color: #444;
}

.card .card-body {
   padding: 8px 12px;
}

.card .w-img img {
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

</style>