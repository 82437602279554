<template>
   <div class="chat-message-container" :class="dadosUsuario.cliente.id == mensagem.idUsuario ? 'reversed' : ''">
      <div class="chat-message-person">
         <div class="chat-message-avatar"><img src="@/assets/cliente/img/logo/icon.png" /></div>
      </div>
      <div class="chat-message-context">
         <div class="chat-message-bubble">
            <span>
               <div class="row m-0 mb-1" :class="dadosUsuario.cliente.id == mensagem.idUsuario ? 'justify-content-end' : ''">
                  <chatMidia v-for="(midia, index) in mensagem.anexos" :key="index" :midia="midia" />
               </div>
               {{ mensagem.mensagem }}
               <small v-if="mensagem.data" class="mt-1"><i class="fad fa-clock me-1"></i>{{ mensagem.data }}</small>
            </span>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'
import chatMidia from '@/components/perfil/ChatMidia.vue'

export default {
   name: 'ChatMensagem',
   props: ['mensagem', 'index'],
   computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario
		})
	},
   components: {
      chatMidia
   }
}

</script>