<template>
   <div class="w-max-content position-relative px-1 mb-2">
      <div v-if="midia.extensao == 'png' || midia.extensao == 'jpg' || midia.extensao == 'jpeg' || midia.extensao == 'ico' || midia.extensao == 'gif' || midia.extensao == 'webp'" class="img hpx-75">
         <img :src="midia.url" @click="openLink" @error="imageError">
      </div>

      <div class="audio-player" v-else-if="midia.extensao == 'mp3' || midia.extensao == 'ogg' || midia.extensao == 'wav'">
         <div ref="playBtn" id="play-btn"></div>
         <div class="audio-wrapper" ref="playerContainer" id="player-container" href="javascript:;">
            <audio ref="player" id="player" @timeupdate="initProgressBar()">
               <source :src="midia.url" type="audio/ogg">
               <source :src="midia.url" type="audio/wav">
               <source :src="midia.url" type="audio/mpeg">
            </audio>
         </div>
         <div class="player-controls scrubber">
            <p class="limitador"><i class="far fa-external-link mx-2" @click="openLink"></i>{{ midia.nome }}</p>
            <small id="seekObjContainer">
               <progress ref="seekObj" id="seekObj" value="0" max="1"></progress>
            </small>
            <small class="float-start start-time mt-1" style="left: 10px;" ref="startTime"></small>
            <small class="float-end end-time mt-1" style="right: 10px;" ref="endTime"></small>
         </div>
      </div>

      <div v-else-if="midia.extensao == 'mp4' || midia.extensao == 'webm'" class="video hpx-75">
         <video @click="openLink">
            <source :src="midia.url" type="video/mp4">
            <source :src="midia.url" type="video/webm">
         </video>
      </div>

      <anexo v-else :anexo="midia" class="wpx-200" />
   </div>
</template>

<script>

import $ from 'jquery'
import anexo from '@/components/perfil/Anexo.vue'

export default {
	name: 'ChatMidia',
   props: ['midia'],
   components: {
      anexo
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      openLink : function () {
         window.open(this.midia.url)
		},
      calculateTotalValue : function(length) {
         var minutes = Math.floor(length / 60),
         seconds_int = length - minutes * 60,
         seconds_str = parseInt(seconds_int).toString(),
         seconds = seconds_str.substr(0, 2),
         time = minutes.toString().padStart(2, '0') + ':' + seconds.padStart(2, '0')

         return time;
      },
      calculateCurrentValue : function(currentTime) {
         var current_minute = parseInt(currentTime / 60) % 60,
         current_seconds_long = currentTime % 60,
         current_seconds = current_seconds_long.toFixed(),
         current_time = current_minute.toString().padStart(2, '0') + ":" + current_seconds.toString().padStart(2, '0')

         return current_time;
      },
      initProgressBar : function() {
         var player = this.$refs.player;
         var length = player.duration;
         var current_time = player.currentTime;

         var totalLength = this.calculateTotalValue(length)
         $(this.$refs.endTime).html(totalLength);

         var currentTime = this.calculateCurrentValue(current_time);
         $(this.$refs.startTime).html(currentTime);

         var progressbar = this.$refs.seekObj;
         progressbar.value = (player.currentTime / player.duration);
         progressbar.addEventListener("click", seek);

         if (player.currentTime == player.duration) {
            $(this.$refs.playBtn).removeClass('pause');
         }

         function seek(evt) {
            var percent = evt.offsetX / this.offsetWidth;
            player.currentTime = percent * player.duration;
            progressbar.value = percent / 100;
         }
      },
      initPlayers : function(num) {
         let ref = this;
         for (var i = 0; i < num; i++) {
            $(function() {
               var player = ref.$refs.player,
               playBtn = ref.$refs.playBtn;

               if (playBtn != null) {
                  playBtn.addEventListener('click', function() {
                     togglePlay()
                  });
               }

               function togglePlay() {
                  if (player.paused === false) {
                     player.pause();
                     // isPlaying = false;
                     $(ref.$refs.playBtn).removeClass('pause');

                  } else {
                     player.play();
                     $(ref.$refs.playBtn).addClass('pause');
                     // isPlaying = true;
                  }
               }
            });
         }
      }
   },
   mounted() {
      if (this.midia.extensao == 'mp3' || this.midia.extensao == 'ogg' || this.midia.extensao == 'wav') {
         setTimeout(() => {
            this.initPlayers($(this.$refs.playerContainer).length);
            
            setTimeout(() => {
               this.initProgressBar()
            }, 1000);
         }, 100);
      }
   }
}

</script>

<style scoped>

video,
img {
   object-fit: cover;
   border-radius: .25rem;
   height: 100%;
   max-width: 100%;
}

.hpx-75 {
   height: 75px;
}

.img,
.video {
   max-width: 200px !important;
   cursor: pointer;
}

.video::after {
   content: "\f04b";
   font-family: "Font Awesome 5 Pro";
   font-weight: 900;
   position: absolute;
   top: 6px;
   left: 12px;
   text-shadow: 1px 1px 1px #000;
   color: var(--color-theme);
}

.img:hover::before,
.video:hover::before,
.video:hover::after {
   content: "";
}

.video::before,
.img::before {
   content: "\f08e";
   font-family: "Font Awesome 5 Pro";
   font-weight: 400;
   position: absolute;
   bottom: 12px;
   right: 12px;
   text-shadow: 1px 1px 1px #000;
   color: var(--color-theme);
}

.audio-player {
   background: #fff;
   border: 1px solid var(--color-theme);
   width: 250px;
   text-align: center;
   display: flex;
   flex-flow: row;
   border-radius: .25rem;
}

.audio-player .player-controls {
   flex: 3;
   padding: 6px 8px 6px 0;
}

.audio-player .player-controls progress {
   width: 90%;
}

.audio-player .player-controls progress[value] {
   -webkit-appearance: none;
   appearance: none;
   background-color: #fff;
   color: var(--color-theme);
   height: 5px;
}

.audio-player .player-controls progress[value]::-webkit-progress-bar {
   background-color: #fff;
   border-radius: 2px;
   border: 1px solid var(--color-theme);
   color: var(--color-theme);
}

.audio-player .player-controls progress::-webkit-progress-value {
   background-color: var(--color-theme);
}

.audio-player .player-controls p {
   font-size: 13px;
   margin-bottom: 0;
   color: var(--color-theme);
}

.audio-player .player-controls p i {
   font-size: 12px;
   cursor: pointer;
}

.audio-player #play-btn {
   margin: 18px 4px 17px 14px!important;
}

.audio-player #play-btn::after {
   content: '\f04b';
   font-family: "Font Awesome 5 Pro";
   font-weight: 400;
   font-size: 25px;
   cursor: pointer;
   color: var(--color-theme);
}

.audio-player #play-btn.pause::after {
   content: '\f04c';
}

.audio-player .start-time,
.audio-player .end-time {
   font-size: 12px;
   color: var(--color-theme);
   position: relative;
}

</style>