<template>
   <div class="row">
      <div class="col-12">
         <div class="section__head mb-20">
            <div class="row section__title mb-0 align-items-end">
               <div class="col"></div>
               <div class="col pb-2">
                  <h3 class="text-center font-16 mb-1">{{ solicitacao.toggle ? solicitacao.campos.codigo ? ('Solicitação cód. '+ solicitacao.campos.codigo) : 'Nova solicitação' : 'Solicitações' }}</h3>
               </div>
               <div class="col text-end">
                  <a href="javascript:;" class="t-y-btn t-y-btn-2" @click="toggleSolicitacao">
                     <i class="far font-12" :class="solicitacao.toggle ? 'fa-undo' : 'fa-plus'"></i>
                     <span class="d-none d-lg-inline ms-1"> {{ solicitacao.toggle ? 'Voltar' : 'Nova solicitação' }}</span>
                  </a>
               </div>
            </div>
         </div>
      </div>

      <!-- Add/Visualizar -->
      <div class="col-12 mb-20 custom-form" v-if="solicitacao.toggle">
         <div class="row">
            <!-- Chat -->
            <div class="col-12" v-if="solicitacao.campos.codigo">
               <div id="chat">
                  <div class="chat-conversation">
                     <div class="w-100 text-center mb-4">
                        <button v-if="solicitacao.chat.mensagens.length % 100 == 0 && solicitacao.chat.mensagens.length > 0" class="t-y-btn t-y-btn-2" @click="getMensagens">Ver mais</button>
                     </div>
                     <chatMensagem v-for="(mensagem, index) in solicitacao.chat.mensagens" :key="index" :mensagem="mensagem" />
                     <chatMensagem v-if="solicitacao.chat.mensagens.length == 0" :mensagem="{'mensagem': 'Seja o primeiro a enviar uma mensagem!', 'anexos': []}" />
                  </div>
                  <div class="chat-conversation-panel">
                     <div class="d-flex overflow-auto mb-1 pb-1" v-if="solicitacao.chat.anexos.length > 0">
                        <anexo v-for="(anexo, index) in solicitacao.chat.anexos" :key="index" :anexo="anexo" :index="index" class="wpx-200 wpx-min-200 me-1 mt-1" 
                           @remover="removeArquivoSolicitacao($event, 'Chat')" />
                     </div>
                     <div class="chat-conversation-container">
                        <button class="chat-conversation-button panel-item btn-icon add-file-button">
                           <label for="chat-files"><i class="far fa-paperclip"></i></label>
                           <input id="chat-files" type="file" ref="files" multiple @change="addArquivoSolicitacao('Chat')" />
                        </button>
                        <input class="chat-conversation-input panel-item" placeholder="Escreva uma mensagem..." v-model="solicitacao.chat.mensagem" @keyup.enter="sendMensagem" />
                        <button class="chat-conversation-button panel-item btn-icon send-message-button" @click="sendMensagem">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true" data-reactid="1036">
                              <line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                           </svg>
                        </button>
                     </div>
                  </div>
               </div>
            </div>

            <!-- Nova solicitacao -->
            <div class="col-12 mb-3" v-else>
               <p class="text-center" v-html="sourcePerfil.solicitacaoNovo"></p>
            </div>
            <div class="col-12 mb-3">
               <div class="w-max-content input-group mx-auto">
                  <span class="input-group-text font-13 weight-500"><i class="far fa-stream font-12 me-2"></i> Tipo do processo</span>
                  <v-select title="Desktop" :searchable="false" class="wpx-250" :options="tiposSolicitacao" v-model="solicitacao.campos.tipo" :disabled="solicitacao.campos.codigo != null" />
                  <select title="Mobile" class="form-control wpx-250" v-model="solicitacao.campos.tipo" :disabled="solicitacao.campos.codigo">
                     <option v-for="(option, index) in tiposSolicitacao" :key="index" :value="option">{{ option }}</option>
                  </select>
               </div>
            </div>
            <div class="col-12 mb-3">
               <p class="text-center" v-if="solicitacao.campos.tipo == 'Requisição de Garantia' || solicitacao.campos.tipo == 'Garantia Comercial'" v-html="sourcePerfil.solicitacaoMsgGarantia"></p>
               <p class="text-center" v-else-if="solicitacao.campos.tipo == 'Registro de Entrega Técnica'" v-html="sourcePerfil.solicitacaoMsgEntrega"></p>
               <p class="text-center" v-else-if="solicitacao.campos.tipo == 'Registro de Revisão'" v-html="sourcePerfil.solicitacaoMsgRevisao"></p>
               <p class="text-center" v-else-if="solicitacao.campos.tipo == 'Problemas com Pedidos'" v-html="sourcePerfil.solicitacaoMsgProblema"></p>
            </div>

            <div class="col-12">
               <!-- Nota fiscal CCM -->
               <div class="card bg-transparent mb-2" v-if="solicitacao.campos.tipo == 'Problemas com Pedidos' && !solicitacao.campos.codigo">
                  <div class="card-body p-3">
                     <div class="row m-0">
                        <div class="col-12 px-1 mb-2">
                           <h6 class="font-15 color-theme"><i class="fas fa-angle-right me-1"></i> Nota fiscal CCM</h6>
                        </div>
                        <div class="col-6 col-xl-5 px-1 mb-2">
                           <label><i class="fas fa-hashtag font-11 me-1"></i> Número NF compra *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.compraNotaNumero" :disabled="solicitacao.campos.compraNotaEmissao" v-mask="'###############'" />
                        </div>
                        <div class="col-6 col-xl-5 px-1 mb-2">
                           <label><i class="fab fa-slack-hash font-12 me-1"></i> Série NF compra *</label>
                           <input type="text" class="form-control" maxlength="5" v-model="solicitacao.campos.compraNotaSerie" :disabled="solicitacao.campos.compraNotaEmissao" />
                        </div>
                        <div class="col-5 col-xl-2 px-1 mb-2 align-self-end">
                           <button class="btn btn-primary font-13 w-100 py-2" @click="buscarNotasUsuario(true)" v-if="solicitacao.campos.compraNotaEmissao"><i class="far fa-exchange font-11 me-1"></i> Trocar</button>
                           <button class="btn btn-primary font-13 w-100 py-2" @click="buscarNotasUsuario(false)" v-else><i class="far fa-search font-11 me-1"></i> Pesquisar</button>
                        </div>
                        <div class="col-7 col-xl-6 px-1 mb-2">
                           <label><i class="far fa-truck font-11 me-1"></i> Transportadora</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.compraNotaTransportadora" disabled />
                        </div>
                        <div class="col-6 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-truck-loading font-11 me-1"></i> Tipo frete</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.compraNotaFrete" disabled />
                        </div>
                        <div class="col-6 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-calendar font-12 me-1"></i> Data emissão</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.compraNotaEmissao" disabled />
                        </div>
                        <div class="col-12 px-1 mb-2" v-if="solicitacao.nota.resultado.length > 0">
                           <div class="row card-title justify-content-center align-items-center my-3 px-12">
                              <div class="wpx-125 px-1">Número</div>
                              <div class="wpx-100 px-1 d-none d-xl-block">Série</div>
                              <div class="wpx-125 px-1">Data emissão</div>
                              <div class="wpx-150 px-1 d-none d-lg-block">Valor</div>
                              <div class="col px-1 d-none d-xxl-block">Transportadora</div>
                              <div class="wpx-175 px-1"></div>
                              <div class="wpx-75 px-1"></div>
                           </div>

                           <ul class="container-scroll">
                              <nota v-for="(nota, index) in solicitacao.nota.resultado" :key="index" :nota="nota" :index="index" 
                                 @select="setNotaSolicitacao($event, false)" @devolucaoTotal="setNotaSolicitacao($event, true)" />
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Proprietario -->
               <div class="card bg-transparent mb-2" v-if="solicitacao.campos.tipo != 'Problemas com Pedidos'">
                  <div class="card-body p-3">
                     <div class="row m-0">
                        <div class="col-12 px-1 mb-2">
                           <h6 class="font-15 color-theme"><i class="fas fa-angle-right me-1"></i> Proprietário</h6>
                        </div>
                        <div class="col-sm-6 col-xl-3 px-1 mb-2">
                           <label><i class="fas fa-hashtag font-11 me-1"></i> Número NF venda *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.notaNumero" :disabled="solicitacao.campos.codigo" v-mask="'###############'" />
                        </div>
                        <div class="col-sm-6 col-xl-3 px-1 mb-2">
                           <label><i class="fab fa-slack-hash font-12 me-1"></i> Série NF venda *</label>
                           <input type="text" class="form-control" maxlength="5" v-model="solicitacao.campos.notaSerie" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-md-6 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-calendar font-12 me-1"></i> Data da venda *</label>
                           <input type="date" class="form-control" v-model="solicitacao.campos.dataVenda" :disabled="solicitacao.campos.codigo" />
                           <!-- <datePicker format="DD/MM/YYYY" :clearable="false" :editable="false" v-model="solicitacao.campos.dataVenda" :disabled="solicitacao.campos.codigo"></datePicker> -->
                        </div>
                        <div class="col-md-6 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-paperclip font-11 me-1"></i> Anexar NF</label>
                           <div class="input-group" v-if="solicitacao.campos.codigo">
                              <input type="text" class="form-control" v-model="solicitacao.campos.anexoNF" disabled />
                              <a :href="solicitacao.campos.anexoNF" target="_blank" class="input-group-text font-13" v-if="solicitacao.campos.anexoNF != null">
                                 <i class="fas fa-external-link"></i>
                              </a>
                           </div>
                           <div class="input-group" v-else>
                              <input type="file" class="form-control" ref="anexoNF" @change="addArquivoSolicitacao('NF')" />
                              <a href="javascript:;" class="input-group-text font-13" v-if="solicitacao.campos.anexoNF != null" @click="removeArquivoSolicitacao(null, 'NF')">
                                 <i class="far fa-trash text-danger"></i>
                              </a>
                           </div>
                        </div>
                        <div class="col-md-7 col-xl-9 px-1 mb-2">
                           <label><i class="far fa-user font-12 me-1"></i> Nome *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioNome" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-sm-6 col-md-5 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-phone-alt font-12 me-1"></i> Telefone *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioFone" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-sm-6 col-md-5 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-location-arrow font-12 me-1"></i> CEP *</label>
                           <div class="input-group">
                              <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioCEP" :disabled="solicitacao.campos.codigo" @keyup.enter="buscarCEP" />
                              <a href="javascript:;" class="input-group-text font-13" @click="buscarCEP" v-if="!solicitacao.campos.codigo">
                                 <i class="fas fa-search"></i>
                              </a>
                           </div>
                        </div>
                        <div class="col-md-7 col-xl-6 px-1 mb-2">
                           <label><i class="far fa-map-signs font-12 me-1"></i> Endereço *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioEndereco" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-md-3 px-1 mb-2">
                           <label><i class="far fa-map-marker-alt font-12 me-1"></i> Nº Endereço *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioEnderecoNumero" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-sm-6 col-md-5 col-xl-8 px-1 mb-2">
                           <label><i class="far fa-location font-12 me-1"></i> Cidade *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioCidade" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-sm-6 col-md-4 px-1 mb-2">
                           <label><i class="far fa-map font-12 me-1"></i> Estado *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.proprietarioEstado" :disabled="solicitacao.campos.codigo" />
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Motivo devolucao -->
               <div class="card bg-transparent mb-2" v-else-if="solicitacao.campos.devolucaoTotal">
                  <div class="card-body p-3">
                     <div class="row m-0">
                        <div class="col-12 px-1">
                           <h6 class="font-15 color-theme mb-3"><i class="fas fa-angle-right me-1"></i> Motivo devolução</h6>
                           <v-select title="Desktop" :options="tiposProblema" :searchable="false" v-model="solicitacao.campos.motivoDevolucao" placeholder="Selecione uma opção">
                              <template v-slot:no-options>Sem resultados</template>
                           </v-select>
                           <select title="Mobile" class="form-control" v-model="solicitacao.campos.motivoDevolucao">
                              <option :value="undefined" disabled>Selecione uma opção</option>
                              <option v-for="(option, index) in tiposProblema" :key="index" :value="option">{{ option }}</option>
                           </select>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Produto(s) -->
               <div class="card bg-transparent mb-2" v-if="!solicitacao.campos.devolucaoTotal">
                  <div class="card-body p-3">
                     <h6 class="font-15 color-theme mb-3">
                        <span><i class="fas fa-angle-right me-1"></i> Produto</span>
                        <span v-if="solicitacao.campos.tipo != 'Requisição de Garantia' && solicitacao.campos.tipo != 'Garantia Comercial' && solicitacao.campos.tipo != 'Registro de Revisão'">s</span>
                     </h6>
                     <div class="row m-0" v-if="!solicitacao.campos.codigo">
                        <div class="col-sm-6 px-1 mb-2" :class="solicitacao.campos.tipo == 'Problemas com Pedidos' ? 'col-xl-4' : 'col-xl-3'">
                           <label><i class="fas fa-hashtag font-11 me-1"></i> Código CCM *</label>
                           <div v-if="solicitacao.campos.tipo == 'Problemas com Pedidos'" class="input-group">
                              <input type="text" class="form-control" v-model="solicitacao.produto.codigo" :disabled="String(solicitacao.produto.codigo).trim() != ''" />
                              <a href="javascript:;" class="input-group-text font-13" v-if="String(solicitacao.produto.codigo).trim() != ''" @click="solicitacao.produto.codigo = '';">
                                 <i class="far fa-trash text-danger"></i>
                              </a>
                           </div>
                           <input v-else type="text" class="form-control" v-model="solicitacao.produto.codigo" />
                        </div>
                        <div class="col-sm-6 px-1 mb-2" :class="solicitacao.campos.tipo == 'Problemas com Pedidos' ? 'col-xl-4' : 'col-xl-3'">
                           <label><i class="fab fa-slack-hash font-11 me-1"></i> Número série</label>
                           <div class="input-group">
                              <input type="text" class="form-control" v-model="solicitacao.produto.serie" />
                              <a :href="sourcePerfil.solicitacaoUrlGuiaSerie" target="_blank" class="input-group-text font-13">
                                 <i class="fas fa-question"></i>
                              </a>
                           </div>
                        </div>
                        <div class="px-1 mb-2" :class="solicitacao.campos.tipo == 'Problemas com Pedidos' ? 'col-sm-6 col-xl-4' : 'col-md-8 col-xl-4'">
                           <label><i class="far fa-file font-12 me-1"></i> Foto do nº série</label>
                           <div class="input-group">
                              <input type="file" class="form-control" ref="anexoSerie" @change="addArquivoSolicitacao('Série')" />
                              <a href="javascript:;" class="input-group-text font-13" v-if="solicitacao.produto.anexoSerie != null" @click="removeArquivoSolicitacao(null, 'Série')">
                                 <i class="far fa-trash text-danger"></i>
                              </a>
                           </div>
                        </div>
                        <div class="px-1 mb-2" v-if="solicitacao.campos.tipo == 'Problemas com Pedidos'" :class="tiposProblemaCodigo.includes(solicitacao.produto.tipoProblema) || tiposProblemaQuantidade.includes(solicitacao.produto.tipoProblema) ? 'col-sm-6 col-xl-4' : 'col-sm-6 col-xl-10'">
                           <label><i class="far fa-stream font-11 me-1"></i> Tipo</label>
                           <v-select title="Desktop" :options="tiposProblema" :searchable="false" v-model="solicitacao.produto.tipoProblema" placeholder="Selecione uma opção">
                              <template v-slot:no-options>Sem resultados</template>
                           </v-select>
                           <select title="Mobile" class="form-control" v-model="solicitacao.produto.tipoProblema">
                              <option :value="undefined" disabled>Selecione uma opção</option>
                              <option v-for="(option, index) in tiposProblema" :key="index" :value="option">{{ option }}</option>
                           </select>
                        </div>
                        <div class="col-md-8 col-xl-6 px-0" v-if="solicitacao.campos.tipo == 'Problemas com Pedidos' && (tiposProblemaCodigo.includes(solicitacao.produto.tipoProblema) || tiposProblemaQuantidade.includes(solicitacao.produto.tipoProblema))">
                           <div class="row m-0">
                              <div class="col px-1 mb-2" v-if="tiposProblemaCodigo.includes(solicitacao.produto.tipoProblema)">
                                 <label v-if="solicitacao.produto.tipoProblema == 'Produto Avariado'"><i class="fas fa-hashtag font-11 me-1"></i> Cód. Avariado *</label>
                                 <label v-else-if="solicitacao.produto.tipoProblema == 'Produto Incompleto'"><i class="fas fa-hashtag font-11 me-1"></i> Cód. Faltante *</label>
                                 <label v-else-if="solicitacao.produto.tipoProblema == 'Produto Errado'"><i class="fas fa-hashtag font-11 me-1"></i> Cód. Recebido</label>
                                 <label v-else><i class="fas fa-hashtag font-11 me-1"></i> Código</label>
                                 <input type="text" class="form-control" v-model="solicitacao.produto.codigo2" />
                              </div>
                              <div class="col px-1 mb-2" v-if="tiposProblemaQuantidade.includes(solicitacao.produto.tipoProblema)">
                                 <label v-if="solicitacao.produto.tipoProblema == 'Produto Avariado'"><i class="far fa-box font-11 me-1"></i> Qtd. Avariada *</label>
                                 <label v-else-if="solicitacao.produto.tipoProblema == 'Quantidade Divergente' || solicitacao.produto.tipoProblema == 'Produto Incompleto'"><i class="far fa-box font-11 me-1"></i> Qtd. Faltante</label>
                                 <label v-else><i class="far fa-box font-11 me-1"></i> Quantidade *</label>
                                 <input type="text" class="form-control" v-model="solicitacao.produto.quantidade" />
                              </div>
                           </div>
                        </div>
                        <div class="col-md-4 col-xl-2 px-1 mb-2 align-self-end">
                           <button class="btn btn-primary font-13 w-100 py-2" @click="addProdutoSolicitacao" :disabled="solicitacao.campos.produtos.length >= 1 && 
                              (solicitacao.campos.tipo == 'Requisição de Garantia' || solicitacao.campos.tipo == 'Garantia Comercial' || solicitacao.campos.tipo == 'Registro de Revisão')">
                              <i class="far fa-check font-12 me-1"></i> Adicionar
                           </button>
                        </div>
                     </div>

                     <!-- Lista de produtos -->
                     <div class="row m-0">
                        <div class="col-md-6 px-1 mb-2" v-if="solicitacao.campos.tipo == 'Problemas com Pedidos' && solicitacao.nota.produtos.length > 0">
                           <div class="row card-title justify-content-center align-items-center my-3 px-12">
                              <div class="wpx-100 px-1 d-none d-xl-block">Código</div>
                              <div class="col px-1">Nome do produto</div>
                              <div class="wpx-75 px-1"></div>
                           </div>
                           
                           <ul class="container-scroll">
                              <produto v-for="(produto, index) in solicitacao.nota.produtos" :key="index" :produto="produto" :index="index" 
                                 :view="solicitacao.campos.codigo ? 'Linha-Inteira' : 'Linha-Dividida-Adicionar'" 
                                 :produtoSelecionado="solicitacao.produto.codigo" @select="selectProdutoSolicitacao($event)" />
                           </ul>
                        </div>
                        <div class="px-1 mb-1" :class="solicitacao.campos.tipo == 'Problemas com Pedidos' && solicitacao.nota.produtos.length > 0 ? 'col-md-6' : 'col-12'">
                           <div class="row card-title justify-content-center align-items-center my-3 px-12" v-if="solicitacao.campos.tipo == 'Problemas com Pedidos' && !solicitacao.campos.codigo">
                              <div class="wpx-100 px-1 d-none d-xl-block">Código</div>
                              <div class="col px-1">Nome do produto</div>
                              <div class="wpx-75 px-1"></div>
                           </div>
                           
                           <ul class="container-scroll">
                              <produto v-for="(produto, index) in solicitacao.campos.produtos" :key="index" :produto="produto" :index="index" 
                                 :view="solicitacao.campos.codigo ? 'Linha-Inteira' : solicitacao.campos.tipo == 'Problemas com Pedidos' ? 'Linha-Dividida-Remover' : 'Linha-Inteira-Remover'" 
                                 @remover="removeProdutoSolicitacao" />

                              <li class="text-center py-3" v-if="solicitacao.campos.devolucaoTotal == true">Devolução total</li>
                              <li class="text-center py-3" v-else-if="solicitacao.campos.tipo == 'Problemas com Pedidos' && solicitacao.campos.produtos.length == 0">
                                 Nenhum produto selecionado
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Serviço executado -->
               <div class="card bg-transparent mb-2" v-if="solicitacao.campos.tipo == 'Requisição de Garantia' || solicitacao.campos.tipo == 'Garantia Comercial' || solicitacao.campos.tipo == 'Registro de Revisão'">
                  <div class="card-body p-3">
                     <div class="row m-0">
                        <div class="col-12 px-1 mb-2">
                           <h6 class="font-15 color-theme mb-1">
                              <span v-if="solicitacao.campos.tipo == 'Registro de Revisão'"><i class="fas fa-angle-right me-1"></i> Serviço executado</span>
                              <span v-else><i class="fas fa-angle-right me-1"></i> Defeito apresentado</span>
                              <small v-if="solicitacao.campos.tipo == 'Registro de Revisão'" class="text-secondary mt-1 mt-lg-0 ms-lg-2 d-block d-lg-inline">
                                 Os insumos/mão de obra utilizados nas revisões são de responsabilidade do cliente/proprietário
                              </small>
                           </h6>
                        </div>
                        <div class="col-xl-2 px-1 mb-2">
                           <label v-if="solicitacao.campos.tipo == 'Registro de Revisão'"><i class="far fa-calendar font-11 me-1"></i> Data</label>
                           <label v-else><i class="far fa-calendar font-11 me-1"></i> Data da falha</label>
                           <input type="date" class="form-control" v-model="solicitacao.campos.dataFalha" :disabled="solicitacao.campos.codigo" />
                           <!-- <datePicker format="DD/MM/YYYY" :clearable="false" :editable="false" v-model="solicitacao.campos.dataFalha" :disabled="solicitacao.campos.codigo"></datePicker> -->
                        </div>
                        <div class="col-sm-6 col-xl-3 px-1 mb-2">
                           <label><i class="far fa-user-hard-hat font-11 me-1"></i> Somente mão de obra?</label>
                           <v-select title="Desktop" :searchable="false" class="w-100" :options="['Sim', 'Não']" v-model="solicitacao.campos.somenteMaoObra" :disabled="solicitacao.campos.codigo != null" />
                           <select title="Mobile" class="form-control" v-model="solicitacao.campos.somenteMaoObra" :disabled="solicitacao.campos.codigo">
                              <option v-for="(option, index) in ['Sim', 'Não']" :key="index" :value="option">{{ option }}</option>
                           </select>
                        </div>
                        <div class="col-sm-6 col-xl-3 px-1 mb-2" v-if="solicitacao.campos.somenteMaoObra == 'Não'">
                           <label v-if="solicitacao.campos.tipo == 'Registro de Revisão'"><i class="far fa-wrench font-12 me-1"></i> Peças substituídas</label>
                           <label v-else><i class="far fa-wrench font-12 me-1"></i> Possui peças de reparo?</label>
                           <v-select title="Desktop" :searchable="false" class="w-100" :options="['Sim', 'Não']" v-model="solicitacao.campos.possuiPecasReparo" :disabled="solicitacao.campos.codigo != null" />
                           <select title="Mobile" class="form-control" v-model="solicitacao.campos.possuiPecasReparo" :disabled="solicitacao.campos.codigo">
                              <option v-for="(option, index) in ['Sim', 'Não']" :key="index" :value="option">{{ option }}</option>
                           </select>
                        </div>
                        <div class="px-1 mb-2" :class="solicitacao.campos.somenteMaoObra == 'Não' ? 'col-xl-4' : 'col-xl-7'">
                           <label><i class="fas fa-ellipsis-h font-11 me-1"></i> Observações</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.observacoes" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-12 px-1">
                           <label v-if="solicitacao.campos.tipo == 'Registro de Revisão'"><i class="far fa-exclamation-circle font-11 me-1"></i> Descrição do serviço</label>
                           <label v-else><i class="far fa-exclamation-circle font-11 me-1"></i> Descrição do defeito</label>
                           <textarea class="form-control" v-model="solicitacao.campos.descricaoDefeito" :disabled="solicitacao.campos.codigo"></textarea>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Observacoes -->
               <div class="card bg-transparent mb-2" v-if="solicitacao.campos.tipo == 'Problemas com Pedidos'">
                  <div class="card-body p-3">
                     <div class="row m-0">
                        <div class="col-12 px-1">
                           <h6 class="font-15 color-theme mb-3"><i class="fas fa-angle-right me-1"></i> Observações</h6>
                           <textarea class="form-control" v-model="solicitacao.campos.observacoes" :disabled="solicitacao.campos.codigo"></textarea>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Pecas -->
               <div class="card bg-transparent mb-2" v-if="solicitacao.campos.tipo == 'Requisição de Garantia' || solicitacao.campos.tipo == 'Garantia Comercial'">
                  <div class="card-body p-3 pb-2" v-if="solicitacao.vistaExp != null">
                     <h6 class="font-15 color-theme mb-3"><i class="fas fa-angle-right me-1"></i> Peças substituídas</h6>
                     <vistaExplodida :view="'Solicitacao'" :produto="solicitacao.vistaExp" :pecasSelecionadas="solicitacao.campos.pecas" 
                        @addCarrinho="addPecaSolicitacao($event)" @removePeca="removePecaSolicitacao($event)" />
                  </div>
                  <div class="card-body p-3 pb-2" v-else-if="solicitacao.campos.pecas.length > 0">
                     <h6 class="font-15 color-theme mb-10"><i class="fas fa-angle-right me-1"></i> Peças substituídas</h6>
                     <vistaExplodida :view="'Solicitacao-View'" :pecasSelecionadas="solicitacao.campos.pecas" />
                  </div>
               </div>

               <!-- Anexos -->
               <div class="card bg-transparent mb-2" v-if="!solicitacao.campos.codigo || solicitacao.campos.anexoFotos.length > 0">
                  <div class="card-body p-3 pb-2">
                     <div class="row m-0">
                        <div class="col-12 px-1 mb-2">
                           <h6 class="font-15 color-theme"><i class="fas fa-angle-right me-1"></i> Anexos</h6>
                        </div>
                        <div class="px-1 mb-2" :class="solicitacao.campos.anexoFotos.length == 0 ? 'col-12' : 'col-md-6'" v-if="!solicitacao.campos.codigo">
                           <label for="file-upload" class="custom-file-upload">
                              <strong class="color-theme">Escolher arquivos</strong><br>
                              <span v-if="solicitacao.campos.anexoFotos.length == 0"><i class="far fa-paperclip font-12 me-1"></i> Anexar fotos do produto</span>
                              <span v-else><i class="far fa-paperclip font-12 me-1"></i> {{ solicitacao.campos.anexoFotos.length }} arquivo(s) selecionado(s)</span>
                           </label>
                           <input id="file-upload" type="file" class="custom-file-box" ref="anexoFotos" multiple
                              @change="addArquivoSolicitacao('Fotos')" />
                        </div>
                        <div class="px-1 mb-2 overflow-150" :class="solicitacao.campos.codigo ? 'col-12' : 'col-md-6'" v-if="solicitacao.campos.anexoFotos.length > 0">
                           <div class="cards-170 cards-sm-200">
                              <anexo v-for="(anexo, index) in solicitacao.campos.anexoFotos" :key="index" :index="index" :anexo="anexo" 
                                 @remover="removeArquivoSolicitacao($event, 'Fotos')" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Comprovante de entrega -->
               <div class="card bg-transparent mb-2" v-if="solicitacao.campos.tipo == 'Registro de Entrega Técnica'">
                  <div class="card-body p-3 pb-2">
                     <div class="row m-0">
                        <div class="col-12 px-1 mb-2">
                           <h6 class="font-15 color-theme"><i class="fas fa-angle-right me-1"></i> Comprovante de entrega</h6>
                        </div>
                        <div class="col-12 px-1 mb-2">
                           <label><i class="far fa-paperclip font-11 me-1"></i> Anexar comprovante</label>
                           <div class="input-group" v-if="solicitacao.campos.codigo">
                              <input type="text" class="form-control" v-model="solicitacao.campos.anexoEntrega" disabled />
                              <a :href="solicitacao.campos.anexoEntrega" target="_blank" class="input-group-text font-13" v-if="solicitacao.campos.anexoEntrega != null">
                                 <i class="fas fa-external-link"></i>
                              </a>
                           </div>
                           <div class="input-group" v-else>
                              <input type="file" class="form-control" ref="anexoEntrega" @change="addArquivoSolicitacao('Entrega')" />
                              <a href="javascript:;" class="input-group-text font-13" v-if="solicitacao.campos.anexoEntrega != null" @click="removeArquivoSolicitacao(null, 'Entrega')">
                                 <i class="far fa-trash text-danger"></i>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <!-- Email/Enviar -->
               <div class="card bg-transparent mb-2">
                  <div class="card-body p-3">
                     <div class="row m-0">
                        <div class="col-sm-6 col-lg-8 px-1 mb-2">
                           <label><i class="far fa-envelope font-11 me-1"></i> Email *</label>
                           <input type="text" class="form-control" v-model="solicitacao.campos.email" :disabled="solicitacao.campos.codigo" />
                        </div>
                        <div class="col-sm-6 col-lg-4 px-1 mb-2 align-self-end">
                           <button class="btn btn-primary font-13 w-100 py-2" @click="addSolicitacao" :disabled="solicitacao.campos.codigo">
                              <i class="far fa-check font-12 me-1"></i> Enviar solicitação
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      
      <!-- Listagem -->
      <div class="col-12 mb-20" v-else>
         <!-- Nav -->
         <ul class="nav nav-pills mb-3" id="solicitacoes-pills-tab" role="tablist">
            <li class="col-3 nav-item" role="presentation">
               <button class="nav-link w-100 active" id="garantias-tab" data-bs-toggle="pill" data-bs-target="#garantias" type="button" role="tab" aria-controls="garantias" aria-selected="true">
                  <span class="d-flex align-items-center justify-content-center weight-500">
                     <i class="fal fa-file-signature me-lg-2"></i><span class="d-none d-lg-block">Garantias</span>
                  </span>
               </button>
            </li>
            <li class="col-3 nav-item" role="presentation">
               <button class="nav-link w-100" id="entregas-tab" data-bs-toggle="pill" data-bs-target="#entregas" type="button" role="tab" aria-controls="entregas" aria-selected="false">
                  <span class="d-flex align-items-center justify-content-center weight-500">
                     <i class="fal fa-wrench me-lg-2"></i><span class="d-none d-lg-block">Entrega Técnica</span>
                  </span>
               </button>
            </li>
            <li class="col-3 nav-item" role="presentation">
               <button class="nav-link w-100" id="problemas-tab" data-bs-toggle="pill" data-bs-target="#problemas" type="button" role="tab" aria-controls="problemas" aria-selected="false">
                  <span class="d-flex align-items-center justify-content-center weight-500">
                     <i class="fal fa-exclamation-triangle me-lg-2"></i><span class="d-none d-lg-block">Problemas c/ pedido</span>
                  </span>
               </button>
            </li>
            <li class="col-3 nav-item" role="presentation">
               <button class="nav-link w-100" id="revisoes-tab" data-bs-toggle="pill" data-bs-target="#revisoes" type="button" role="tab" aria-controls="revisoes" aria-selected="false">
                  <span class="d-flex align-items-center justify-content-center weight-500">
                     <i class="fal fa-star me-lg-2"></i><span class="d-none d-lg-block">Revisões</span>
                  </span>
               </button>
            </li>
         </ul>

         <!-- Corpo -->
         <div class="tab-content" id="solicitacoes-pills-tabContent">
            <!-- Garantias -->
            <div class="tab-pane fade show active" id="garantias" role="tabpanel" aria-labelledby="garantias-tab">
               <div class="row">
                  <div class="col-sm-8 pe-sm-1" v-if="solicitacao.garantia.score.score != false">
                     <div class="card">
                        <div class="card-body p-2">
                           <highcharts :options="solicitacao.garantia.score.grafico" />
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-4 ps-sm-1 mt-1 mt-sm-0" v-if="solicitacao.garantia.score.score != false">
                     <div class="card h-100">
                        <div class="card-body p-sm-2">
                           <div class="row align-items-center h-100 text-center">
                              <div class="col-12">
                                 <span class="d-sm-block font-20 weight-500 color-theme me-5 me-sm-0 mb-sm-3">Meu score</span>
                                 <span class="d-sm-block font-16" title="% de solicitações concedidas">
                                    {{ solicitacao.garantia.score.score == null ? '-' : solicitacao.garantia.score.score +'%' }}
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 mb-2 mt-3">
                     <div class="input-group">
                        <i class="far fa-search input-group-text"></i>
                        <input type="text" class="form-control" placeholder="Código, produto ou nome do cliente" v-model="solicitacao.garantia.busca" @keyup.enter="buscarSolicitacoes('Garantias', 0)">
                        <button class="btn btn-primary font-13" @click="buscarSolicitacoes('Garantias', 0)">
                           <i class="far fa-search font-11"></i><span class="ms-1 d-none d-sm-inline">Pesquisar</span>
                        </button>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="row card-title justify-content-center align-items-center mt-2 mb-3 px-12" v-if="solicitacao.garantia.resultado.length > 0">
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-hashtag font-11 me-1"></i> Código</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-barcode font-11 me-1"></i> Produto</div>
                        <div class="col px-1 d-none d-xxl-block"><i class="far fa-user font-10 me-1"></i> Nome do cliente</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data abertura</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data alteração</div>
                        <div class="wpx-185 px-1 d-none d-xxl-block"><i class="far fa-exchange-alt font-11 me-1"></i> Status</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"></div>
                     </div>

                     <ul>
                        <solicitacao v-for="(solicitacao, index) in solicitacao.garantia.resultado" :key="index +'-G'" :solicitacao="solicitacao" @visualizar="startWsSolicitacao($event)" />
                        
                        <li class="text-center py-5" v-if="solicitacao.garantia.resultado.length == 0">Nenhuma solicitação encontrada</li>
                        <li class="text-center mt-40" v-else-if="solicitacao.garantia.resultado.length % 50 == 0">
                           <button type="button" class="t-y-btn t-y-btn-2" @click="buscarSolicitacoes('Garantias', null)">Ver mais</button>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <!-- Entregas -->
            <div class="tab-pane fade" id="entregas" role="tabpanel" aria-labelledby="entregas-tab">
               <div class="row">
                  <div class="col-sm-8 pe-sm-1" v-if="solicitacao.entrega.score.score != false">
                     <div class="card">
                        <div class="card-body p-2">
                           <highcharts :options="solicitacao.entrega.score.grafico" />
                        </div>
                     </div>
                  </div>
                  <div class="col-sm-4 ps-sm-1 mt-1 mt-sm-0" v-if="solicitacao.entrega.score.score != false">
                     <div class="card h-100">
                        <div class="card-body p-sm-2">
                           <div class="row align-items-center h-100 text-center">
                              <div class="col-12">
                                 <span class="d-sm-block font-20 weight-500 color-theme me-5 me-sm-0 mb-sm-3">Meu score</span>
                                 <span class="d-sm-block font-16" title="% de solicitações concedidas">
                                    {{ solicitacao.entrega.score.score == null ? '-' : solicitacao.entrega.score.score +'%' }}
                                 </span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-12 mb-2 mt-3">
                     <div class="input-group">
                        <i class="far fa-search input-group-text"></i>
                        <input type="text" class="form-control" placeholder="Código, produto ou nome do cliente" v-model="solicitacao.entrega.busca" @keyup.enter="buscarSolicitacoes('Entrega Técnica', 0)">
                        <button class="btn btn-primary font-13" @click="buscarSolicitacoes('Entrega Técnica', 0)">
                           <i class="far fa-search font-11"></i><span class="ms-1 d-none d-sm-inline">Pesquisar</span>
                        </button>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="row card-title justify-content-center align-items-center mt-2 mb-3 px-12" v-if="solicitacao.entrega.resultado.length > 0">
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-hashtag font-11 me-1"></i> Código</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-barcode font-11 me-1"></i> Produto</div>
                        <div class="col px-1 d-none d-xxl-block"><i class="far fa-user font-10 me-1"></i> Nome do cliente</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data abertura</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data alteração</div>
                        <div class="wpx-185 px-1 d-none d-xxl-block"><i class="far fa-exchange-alt font-11 me-1"></i> Status</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"></div>
                     </div>

                     <ul>
                        <solicitacao v-for="(solicitacao, index) in solicitacao.entrega.resultado" :key="index +'-E'" :solicitacao="solicitacao" 
                           @visualizar="startWsSolicitacao($event)" />
                        
                        <li class="text-center py-5" v-if="solicitacao.entrega.resultado.length == 0">Nenhuma solicitação encontrada</li>
                        <li class="text-center mt-40" v-else-if="solicitacao.entrega.resultado.length % 50 == 0">
                           <button type="button" class="t-y-btn t-y-btn-2" @click="buscarSolicitacoes('Entrega Técnica', null)">Ver mais</button>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <!-- Problemas -->
            <div class="tab-pane fade" id="problemas" role="tabpanel" aria-labelledby="problemas-tab">
               <div class="row">
                  <div class="col-12 mb-2">
                     <div class="input-group">
                        <i class="far fa-search input-group-text"></i>
                        <input type="text" class="form-control" placeholder="Código, produto ou nome do cliente" v-model="solicitacao.problema.busca" @keyup.enter="buscarSolicitacoes('Problemas com Pedido', 0)">
                        <button class="btn btn-primary font-13" @click="buscarSolicitacoes('Problemas com Pedido', 0)">
                           <i class="far fa-search font-11"></i><span class="ms-1 d-none d-sm-inline">Pesquisar</span>
                        </button>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="row card-title justify-content-center align-items-center mt-2 mb-3 px-12" v-if="solicitacao.problema.resultado.length > 0">
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-hashtag font-11 me-1"></i> Código</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-barcode font-11 me-1"></i> Produto</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data abertura</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data alteração</div>
                        <div class="col px-1 d-none d-xxl-block"><i class="far fa-exchange-alt font-11 me-1"></i> Status</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"></div>
                     </div>

                     <ul>
                        <solicitacao v-for="(solicitacao, index) in solicitacao.problema.resultado" :key="index +'-P'" :solicitacao="solicitacao" 
                           @visualizar="startWsSolicitacao($event)" />
                        
                        <li class="text-center py-5" v-if="solicitacao.problema.resultado.length == 0">Nenhuma solicitação encontrada</li>
                        <li class="text-center mt-40" v-else-if="solicitacao.problema.resultado.length % 50 == 0">
                           <button type="button" class="t-y-btn t-y-btn-2" @click="buscarSolicitacoes('Problemas com Pedido', null)">Ver mais</button>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>

            <!-- Revisoes -->
            <div class="tab-pane fade" id="revisoes" role="tabpanel" aria-labelledby="revisoes-tab">
               <div class="row">
                  <div class="col-12 mb-2">
                     <div class="input-group">
                        <i class="far fa-search input-group-text"></i>
                        <input type="text" class="form-control" placeholder="Código, produto ou nome do cliente" v-model="solicitacao.revisao.busca" @keyup.enter="buscarSolicitacoes('Revisões', 0)">
                        <button class="btn btn-primary font-13" @click="buscarSolicitacoes('Revisões', 0)">
                           <i class="far fa-search font-11"></i><span class="ms-1 d-none d-sm-inline">Pesquisar</span>
                        </button>
                     </div>
                  </div>
                  <div class="col-12">
                     <div class="row card-title justify-content-center align-items-center mt-2 mb-3 px-12" v-if="solicitacao.revisao.resultado.length > 0">
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-hashtag font-11 me-1"></i> Código</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"><i class="far fa-barcode font-11 me-1"></i> Produto</div>
                        <div class="col px-1 d-none d-xxl-block"><i class="far fa-user font-10 me-1"></i> Nome do cliente</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data abertura</div>
                        <div class="wpx-135 px-1 d-none d-xxl-block"><i class="far fa-calendar font-11 me-1"></i>Data alteração</div>
                        <div class="wpx-185 px-1 d-none d-xxl-block"><i class="far fa-exchange-alt font-11 me-1"></i> Status</div>
                        <div class="wpx-125 px-1 d-none d-xxl-block"></div>
                     </div>

                     <ul>
                        <solicitacao v-for="(solicitacao, index) in solicitacao.revisao.resultado" :key="index +'-R'" :solicitacao="solicitacao" 
                           @visualizar="startWsSolicitacao($event)" />
                        
                        <li class="text-center py-5" v-if="solicitacao.revisao.resultado.length == 0">Nenhuma solicitação encontrada</li>
                        <li class="text-center mt-40" v-else-if="solicitacao.revisao.resultado.length % 50 == 0">
                           <button type="button" class="t-y-btn t-y-btn-2" @click="buscarSolicitacoes('Revisões', null)">Ver mais</button>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import nota from '@/components/perfil/Nota.vue'
import anexo from '@/components/perfil/Anexo.vue'
import produto from '@/components/perfil/SolicitacaoProduto.vue'
import solicitacao from '@/components/perfil/Solicitacao.vue'
import chatMensagem from '@/components/perfil/ChatMensagem.vue'
import vistaExplodida from '@/components/produtos/VistaExplodida.vue'

export default {
   name: 'TabSolicitacoes',
   data: function () {
		return {
         chatWebSocket: null,
         tiposSolicitacao: ['Requisição de Garantia', 'Garantia Comercial', 'Registro de Entrega Técnica', 'Registro de Revisão', 'Problemas com Pedidos'],
         tiposProblema: [],
         tiposProblemaCodigo: ['Produto Avariado', 'Produto Incompleto', 'Produto Errado'],
         tiposProblemaQuantidade: ['Quantidade Divergente', 'Produto Avariado', 'Produto Incompleto'],
         solicitacao: {
            'toggle': false, 
            'garantia': {'busca': '', 'pagina': 0, 'resultado': [], 'score': {'score': '100.00', 'grafico': {'title': null, 'chart': {'type': 'spline', 'height': 150}, 'credits': {'enabled': false}, 'xAxis': {'categories': []}, 'series': []}}}, 
            'entrega': {'busca': '', 'pagina': 0, 'resultado': [], 'score': {'score': '100.00', 'grafico': {'title': null, 'chart': {'type': 'spline', 'height': 150}, 'credits': {'enabled': false}, 'xAxis': {'categories': []}, 'series': []}}}, 
            'problema': {'busca': '', 'pagina': 0, 'resultado': []}, 
            'revisao': {'busca': '', 'pagina': 0, 'resultado': []},
            'produto': {'codigo': '', 'nome': '', 'serie': '', 'anexoSerie': null, 'tipoProblema': null, 'codigo2': '', 'quantidade': 1},
            'nota': {'resultado': [], 'produtos': []},
            'chat': {'mensagem': '', 'mensagens': [], 'anexos': []},
            'vistaExp': null,
            'campos': {
               'codigo': null, 'notaNumero': '', 'notaSerie': '', 'dataVenda': new Date().toISOString().split('T')[0], 'proprietarioNome': '', 'proprietarioFone': '', 'proprietarioCEP': '', 
               'proprietarioEndereco': '', 'compraNotaNumero': '', 'compraNotaSerie': '', 'compraNotaTransportadora': null, 'compraNotaFrete': null, 'compraNotaEmissao': null, 'anexoNF': null, 
               'anexoEntrega': null, 'tipo': 'Requisição de Garantia', 'email': '','proprietarioEnderecoNumero': '', 'proprietarioCidade': '', 'proprietarioEstado': '', 
               'dataFalha': new Date().toISOString().split('T')[0], 'somenteMaoObra': 'Não', 'possuiPecasReparo': 'Não', 'observacoes': '', 'descricaoDefeito': '', 'produtos': [], 'pecas': [], 
               'anexoFotos': [], 'anexoSeries': [], 'devolucaoTotal': false, 'motivoDevolucao': null
            }
         }
		}
	},
   computed: {
		...mapState({
			urlSocketChat: state => state.urlSocketChat,
			dadosUsuario: state => state.dadosUsuario,
         sourcePerfil: state => state.source.perfil,
			urlRest: state => state.urlRest
		})
	},
   watch: {
      'solicitacao.toggle' : function (value) {
         if (value == false && this.chatWebSocket != null) {
            this.chatWebSocket.close()
            this.chatWebSocket = null
         }
      },
      'solicitacao.campos.tipo' : function(newVal, oldVal) {
         if (newVal != oldVal && !this.solicitacao.campos.codigo) {
            this.resetCampos(this.solicitacao.toggle, newVal, false)

            if (newVal == 'Problemas com Pedidos') {
               this.buscarMotivosProblema(false)
               this.buscarNotasUsuario(true)
            }
         }
      }
   },
   components: {
      solicitacao, vistaExplodida, chatMensagem, nota, anexo, produto
   },
   methods: {
      toggleSolicitacao : function () {
         if (this.solicitacao.toggle == true) {
            this.resetCampos(false, 'Requisição de Garantia', true)

         } else {
            this.solicitacao.toggle = true;
         }
      },
      resetCampos : function (toggle, tipo, closeWS) {
         this.solicitacao = {
            'toggle': toggle, 
            'garantia': this.solicitacao.garantia, 
            'entrega': this.solicitacao.entrega, 
            'problema': this.solicitacao.problema, 
            'revisao': this.solicitacao.revisao,
            'produto': {'codigo': '', 'nome': '', 'serie': '', 'anexoSerie': null, 'tipoProblema': this.tiposProblema[0], 'codigo2': '', 'quantidade': 1},
            'nota': {'resultado': [], 'produtos': []},
            'chat': {'mensagem': '', 'mensagens': [], 'anexos': []},
            'vistaExp': null,
            'campos': {
               'codigo': null, 'notaNumero': '', 'notaSerie': '', 'dataVenda': new Date().toISOString().split('T')[0], 'proprietarioNome': '', 'proprietarioFone': '', 'proprietarioCEP': '', 
               'proprietarioEndereco': '', 'compraNotaNumero': '', 'compraNotaSerie': '', 'compraNotaTransportadora': null, 'compraNotaFrete': null, 'compraNotaEmissao': null, 'anexoNF': null, 
               'anexoEntrega': null, 'tipo': tipo, 'email': this.dadosUsuario.cliente.email, 'proprietarioEnderecoNumero': '', 'proprietarioCidade': '', 'proprietarioEstado': '', 
               'dataFalha': new Date().toISOString().split('T')[0], 'somenteMaoObra': 'Não', 'possuiPecasReparo': 'Não', 'observacoes': '', 'descricaoDefeito': '', 'produtos': [], 'pecas': [], 
               'anexoFotos': [], 'anexoSeries': [], 'devolucaoTotal': false, 'motivoDevolucao': this.tiposProblema[0]
            }
         }

         if (closeWS && this.chatWebSocket != null) {
            this.chatWebSocket.close()
            this.chatWebSocket = null
         }
      },
      startWsSolicitacao :  function (solicitacao) {
         this.chatWebSocket = new WebSocket(this.urlSocketChat);

         this.chatWebSocket.onmessage = async (event) => {
            let body = JSON.parse(event.data);
            let dados;

            switch (body.tipo) {
               case "getMensagens":
                  var position1 = document.querySelector(".chat-conversation").scrollHeight
                  body.resultado.forEach(msg => this.solicitacao.chat.mensagens.unshift(msg));

                  setTimeout(() => {
                     var position2 = document.querySelector(".chat-conversation").scrollHeight
                     $(".chat-conversation").animate({scrollTop: position2 - position1}, 100);

                     setTimeout(() => {
                        this.$store.dispatch('alternarTelaCarregamento', false)
                     }, 75);
                  }, 300);
               break;
               case "sendMensagem":
                  this.solicitacao.chat.mensagens.push(body.resultado);

                  setTimeout(() => {
                     $(".chat-conversation").animate({scrollTop: document.querySelector(".chat-conversation").scrollHeight}, 100);
                  }, 300);
               break;
               case "getSolicitacao":
                  dados = body.resultado;

                  this.solicitacao.toggle = true;
                  this.solicitacao.chat.mensagens = dados.chat.reverse();
                  this.solicitacao.campos = {
                     'codigo': dados.codigo, 
                     'dataVenda': dados.dataVenda, 
                     'proprietarioEndereco': dados.proprietarioEndereco, 
                     'proprietarioCEP': dados.proprietarioCEP, 
                     'notaSerie': dados.notaSerie, 
                     'proprietarioFone': dados.proprietarioFone,
                     'somenteMaoObra': (dados.somenteMaoObra == 'N' ? 'Não': 'Sim'), 
                     'dataFalha': dados.dataFalha, 
                     'proprietarioCidade': dados.proprietarioCidade, 
                     'observacoes': dados.observacoes, 
                     'proprietarioEstado': dados.proprietarioEstado, 
                     'proprietarioNome': dados.proprietarioNome, 
                     'anexoFotos': dados.anexoFotos == null ? [] : dados.anexoFotos, 
                     'anexoEntrega': dados.anexoEntrega,
                     'produtos': dados.produtos == null ? [] : dados.produtos, 
                     'notaNumero': dados.notaNumero, 
                     'possuiPecasReparo': (dados.possuiPecasReparo == 'N' ? 'Não': 'Sim'), 
                     'pecas': dados.pecas == null ? [] : dados.pecas, 
                     'proprietarioEnderecoNumero': dados.proprietarioEnderecoNumero, 
                     'descricaoDefeito': dados.descricaoDefeito,
                     'email': dados.email, 
                     'anexoNF': dados.anexoNF, 
                     'devolucaoTotal': dados.devolucaoTotal, 
                     'compraNotaNumero': null, 
                     'compraNotaSerie': null, 
                     'compraNotaTransportadora': null, 
                     'compraNotaFrete': null, 
                     'compraNotaEmissao': null, 
                     'tipo': dados.tipo, 
                     'anexoSeries': dados.anexoSeries == null ? [] : dados.anexoSeries // Pendente
                  }

                  Vue.nextTick(function() {
                     $(".chat-conversation").animate({scrollTop: document.querySelector(".chat-conversation").scrollHeight}, 100)
                     this.$store.dispatch('alternarTelaCarregamento', false)
                  }.bind(this));
               break;
            }
         }
         
         this.chatWebSocket.onopen = () => {
            this.$store.dispatch('alternarTelaCarregamento', true)
            this.chatWebSocket.send(JSON.stringify({'tipo': "getSolicitacao", 'valor': solicitacao.codigo, 'idUsuario': this.dadosUsuario.cliente.id}));
         }

         this.chatWebSocket.onclose = () => {
            this.$store.dispatch('alternarTelaCarregamento', false)
            this.chatWebSocket = null;
         }
         
         this.chatWebSocket.onerror = () => {
            this.$store.dispatch('alternarTelaCarregamento', false)
            this.chatWebSocket = null;
         }
      },
      buscarSolicitacoes : function (tipo, pagina) {
         let valorSolicitacao = '';
         let paginaSolicitacao = 0;
			this.$store.dispatch('alternarTelaCarregamento', true)

         switch (tipo) {
            case "Garantias":
               this.solicitacao.garantia.pagina = pagina == null ? ++this.solicitacao.garantia.pagina : pagina;
               paginaSolicitacao = this.solicitacao.garantia.pagina;
               valorSolicitacao = this.solicitacao.garantia.busca;
               this.buscarScoreUsuario("Garantias")
            break;
            case "Entrega Técnica":
               this.solicitacao.entrega.pagina = pagina == null ? ++this.solicitacao.entrega.pagina : pagina;
               paginaSolicitacao = this.solicitacao.entrega.pagina;
               valorSolicitacao = this.solicitacao.entrega.busca;
               this.buscarScoreUsuario("Entrega Técnica")
            break;
            case "Problemas com Pedido":
               this.solicitacao.problema.pagina = pagina == null ? ++this.solicitacao.problema.pagina : pagina;
               paginaSolicitacao = this.solicitacao.problema.pagina;
               valorSolicitacao = this.solicitacao.problema.busca;
            break;
            case "Revisões":
               this.solicitacao.revisao.pagina = pagina == null ? ++this.solicitacao.revisao.pagina : pagina;
               paginaSolicitacao = this.solicitacao.revisao.pagina;
               valorSolicitacao = this.solicitacao.revisao.busca;
            break;
         }

			this.$axios({
				method: 'get',
				url: this.urlRest +'usuario/getSolicitacoes',
            params: {
					tipo: tipo,
					valor: valorSolicitacao,
					pagina: paginaSolicitacao
				}
			}).then(response => {
            if (paginaSolicitacao == 0) {
               switch (tipo) {
                  case "Garantias":
                     this.solicitacao.garantia.resultado = [];
                  break;
                  case "Entrega Técnica":
                     this.solicitacao.entrega.resultado = [];
                  break;
                  case "Problemas com Pedido":
                     this.solicitacao.problema.resultado = [];
                  break;
                  case "Revisões":
                     this.solicitacao.revisao.resultado = [];
                  break;
               }
            }

            switch (tipo) {
               case "Garantias":
                  response.data.forEach(p => this.solicitacao.garantia.resultado.push(p));
               break;
               case "Entrega Técnica":
                  response.data.forEach(p => this.solicitacao.entrega.resultado.push(p));
               break;
               case "Problemas com Pedido":
                  response.data.forEach(p => this.solicitacao.problema.resultado.push(p));
               break;
               case "Revisões":
                  response.data.forEach(p => this.solicitacao.revisao.resultado.push(p));
               break;
            }
			}).catch((error) => {
				if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      buscarScoreUsuario : function (tipo) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'usuario/scoreCliente',
            params: {
					tipo: tipo
				}
			}).then(response => {
            if (response.status == 204) {
               this.solicitacao[tipo == 'Garantias' ? 'garantia' : 'entrega'].score.score = false;

            } else if (Object.keys(response.data).length > 0) {
               this.solicitacao[tipo == 'Garantias' ? 'garantia' : 'entrega'].score = {'score': response.data.score, 'grafico': {
                  chart: {type: 'spline', height: 150},
                  title: null,
                  credits: {enabled: false},
                  legend: {enabled: false},
                  navigation: {buttonOptions: {enabled: false}},
                  xAxis: {categories: response.data.grafico.label},
                  yAxis: {title: {text: null}, labels: {format: 'R${value}'}},
                  tooltip: {
                     crosshairs: true,
                     shared: true,
                     formatter : function () {
                        return 'O valor em <b>'+ this.x +'</b> foi de <b>R$ '+ this.y +'</b>';
                     }
                  },
                  series: [{
                     name: '',
                     color: '#14397f',
                     states: {hover: {color: '#ec2125', borderColor: '#ebebeb'}, select: {color: '#ec2125', borderColor: '#ebebeb'}},
                     data: response.data.grafico.valores
                  }]
               }}
            }
			}).catch((error) => {
				if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      buscarNotasUsuario : function (reset) {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.solicitacao.campos.anexoSeries = [];
         this.solicitacao.campos.produtos = [];
         this.solicitacao.campos.pecas = [];

         if (reset) {
            this.solicitacao.campos.compraNotaTransportadora = null;
            this.solicitacao.campos.compraNotaEmissao = null;
            this.solicitacao.campos.compraNotaNumero = null;
            this.solicitacao.campos.compraNotaSerie = null;
            this.solicitacao.campos.compraNotaFrete = null;
         }

         this.$axios({
            method: 'get',
            url: this.urlRest +'usuario/searchNotasUsuario',
            params: {
               numero: this.solicitacao.campos.compraNotaNumero,
               serie: this.solicitacao.campos.compraNotaSerie
            }
         }).then((response) => {
            this.solicitacao.nota.resultado = response.data

            if (response.data.length == 1) {
               this.setNotaSolicitacao(response.data[0])
            }
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      buscarProdutosNota : function (nota) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'usuario/getProdutosNota',
            params: {
               nota: nota
            }
         }).then((response) => {
            this.solicitacao.nota.produtos = response.data

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      buscarMotivosProblema : function (devolucao) {
         this.$axios({
            method: 'get',
            url: this.urlRest +'usuario/getMotivosProblema',
            params: {
               devolucao: devolucao
            }
         }).then((response) => {
            this.tiposProblema = response.data
            this.solicitacao.produto.tipoProblema = response.data[0]
            this.solicitacao.campos.motivoDevolucao = response.data[0]

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         });
      },
      buscarCEP : function () {
         this.$store.dispatch('buscarCEP', this.solicitacao.campos.proprietarioCEP).then((data) => {
            this.solicitacao.campos.proprietarioEndereco = data.logradouro +' '+ data.bairro
            this.solicitacao.campos.proprietarioCidade = data.localidade
            this.solicitacao.campos.proprietarioEstado = data.uf
			})
      },
      selectProdutoSolicitacao : function (produto) {
         this.solicitacao.produto.codigo = produto.codigo;
         this.solicitacao.produto.nome = produto.nome;
      },
      setNotaSolicitacao : function (nota, devolucaoTotal) {
         this.solicitacao.campos.compraNotaTransportadora = nota.transportador;
         this.solicitacao.campos.compraNotaEmissao = (nota.dataEmissao == null ? null : nota.dataEmissao.dayOfMonth.toString().padStart(2, '0') +'/'+ nota.dataEmissao.monthValue.toString().padStart(2, '0') +'/'+ nota.dataEmissao.year);
         this.solicitacao.campos.compraNotaFrete = nota.tipoFrete;
         this.solicitacao.campos.compraNotaNumero = nota.numero;
         this.solicitacao.campos.compraNotaSerie = nota.serie;
         this.solicitacao.nota.resultado = [];
         
         if (devolucaoTotal !=  true) {
            this.buscarMotivosProblema(false)
            this.buscarProdutosNota(nota.nota)
            this.solicitacao.campos.devolucaoTotal = false;
         } else {
            this.buscarMotivosProblema(true)
            this.solicitacao.campos.devolucaoTotal = true;
         }
      },
      addArquivoSolicitacao : function (local) {
         switch (local) {
            case "Fotos":
               for (let file of this.$refs.anexoFotos.files) {
                  if (file.size < 52428800) {
                     this.solicitacao.campos.anexoFotos.unshift(file);
                  } else {
                     Swal.fire({
                        icon: 'error',
                        title: 'Tamanho excedido',
                        text: 'Arquivo excede 10MB'
                     });
                     
                     break
                  }
               }
            break;
            case "Série":
               for (let file of this.$refs.anexoSerie.files) {
                  if (file.size < 52428800) {
                     this.solicitacao.produto.anexoSerie = file;
                  } else {
                     Swal.fire({
                        icon: 'error',
                        title: 'Tamanho excedido',
                        text: 'Arquivo excede 10MB'
                     });
                     
                     break
                  }
               }
            break;
            case "Entrega":
               for (let file of this.$refs.anexoEntrega.files) {
                  if (file.size < 52428800) {
                     this.solicitacao.campos.anexoEntrega = file;
                  } else {
                     Swal.fire({
                        icon: 'error',
                        title: 'Tamanho excedido',
                        text: 'Arquivo excede 10MB'
                     });
                     
                     break
                  }
               }
            break;
            case "NF":
               for (let file of this.$refs.anexoNF.files) {
                  if (file.size < 52428800) {
                     this.solicitacao.campos.anexoNF = file;
                  } else {
                     Swal.fire({
                        icon: 'error',
                        title: 'Tamanho excedido',
                        text: 'Arquivo excede 10MB'
                     });
                     
                     break
                  }
               }
            break;
            case "Chat":
               if ((this.$refs.files.files.length + this.solicitacao.chat.anexos.length) > 10) {
                  Swal.fire({
                     icon: 'error',
                     title: "Limite alcançado",
                     text: "Limite máximo de anexos excedido (10)."
                  });

                  this.$refs.files.value = "";
                  return
               }

               for (let file of this.$refs.files.files) {
                  if (file.size < 52428800) {
                     this.solicitacao.chat.anexos.push(file)
                  } else {
                     Swal.fire({
                        icon: 'error',
                        title: 'Tamanho excedido',
                        text: 'Arquivo excede 10MB'
                     });

                     break
                  }
               }

               setTimeout(() => {
                  this.$refs.files.value = "";
                  $('textarea').focus()
               }, 250);
            break;
         }
      },
      removeArquivoSolicitacao : function (index, local) {
         switch (local) {
            case "Fotos":
               this.solicitacao.campos.anexoFotos.splice(index, 1)
            break;
            case "Série":
               this.solicitacao.produto.anexoSerie = null;
               this.$refs.anexoSerie.value = "";
            break;
            case "Entrega":
               this.solicitacao.campos.anexoEntrega = null;
               this.$refs.anexoEntrega.value = "";
            break;
            case "NF":
               this.solicitacao.campos.anexoNF = null;
               this.$refs.anexoNF.value = "";
            break;
            case "Chat":
               this.solicitacao.chat.anexos.splice(index, 1)
            break;
         }
      },
      addProdutoSolicitacao : function () {
         if (typeof this.solicitacao.produto.codigo == 'object') {
            this.solicitacao.produto.codigo = this.solicitacao.produto.codigo.codigo
         }

         if (this.solicitacao.produto.codigo == null || String(this.solicitacao.produto.codigo).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Código inválido!'
            });

            return
         } else if (this.solicitacao.produto.anexoSerie == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Foto do nº série inválida!'
            });

            return
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'usuario/getProdutoSolicitacao',
            params: {
               codigo: this.solicitacao.produto.codigo,
               serie: this.solicitacao.produto.serie != null & this.solicitacao.produto.anexoSerie != null ? this.solicitacao.produto.serie : null
            }
         }).then((response) => {
            if (this.solicitacao.campos.tipo != 'Problemas com Pedidos') {
               this.solicitacao.produto.tipoProblema = null;
               this.solicitacao.produto.quantidade = null;
               this.solicitacao.produto.codigo2 = null;
               
            } else if (this.solicitacao.produto.tipoProblema == 'Quantidade Divergente') {
               this.solicitacao.produto.codigo2 = null;
            }

            this.solicitacao.produto.nome = response.data.descricao
            this.solicitacao.produto.codigo = response.data.codigo
            this.solicitacao.vistaExp = response.data
            this.solicitacao.campos.anexoSeries.unshift(this.solicitacao.produto.anexoSerie)
            this.solicitacao.campos.produtos.unshift(JSON.parse(JSON.stringify(this.solicitacao.produto)))
            
            setTimeout(() => {
               this.solicitacao.produto = {'codigo': '', 'nome': '', 'serie': '', 'anexoSerie': null, 'tipoProblema': this.tiposProblema[0], 'codigo2': '', 'quantidade': 1}
               this.$refs.anexoSerie.value = "";
               this.$store.dispatch('alternarTelaCarregamento', false)
            }, 250);
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
               } else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Produto não encontrado!'
						});
               } else if (error.response.status == 403) {
						this.$toast.fire({
							icon: 'error',
							title: 'Série inválida!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      removeProdutoSolicitacao : function (index) {
         this.solicitacao.campos.produtos.splice(index, 1)
         this.solicitacao.campos.anexoSeries.splice(index, 1)

         if (this.solicitacao.campos.produtos.length == 0) {
            this.solicitacao.vistaExp = null;
         }
      },
      addPecaSolicitacao : function (peca) {
         this.solicitacao.campos.pecas.unshift(peca)
      },
      removePecaSolicitacao : function (index) {
         this.solicitacao.campos.pecas.splice(index, 1)
      },
      addSolicitacao : function () {
         if (this.solicitacao.campos.email == null || String(this.solicitacao.campos.email).trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Email inválido!'
            });

            return
         } else if (this.solicitacao.campos.produtos.length == 0 && this.solicitacao.campos.devolucaoTotal != true) {
            this.$toast.fire({
               icon: 'error',
               title: 'Produto(s) inválido(s)!'
            });

            return
         } else if (this.solicitacao.campos.anexoFotos.length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: 'Anexo(s) inválido(s)!'
            });

            return
         }
         
         if (this.solicitacao.campos.tipo == 'Requisição de Garantia' || this.solicitacao.campos.tipo == 'Garantia Comercial') {
            if (this.solicitacao.campos.dataFalha == null || String(this.solicitacao.campos.dataFalha).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Data falha inválida!'
               });

               return
            } else if (this.solicitacao.campos.descricaoDefeito == null || String(this.solicitacao.campos.descricaoDefeito).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Descrição defeito inválida!'
               });

               return
            }
         } 
         
         if (this.solicitacao.campos.tipo == 'Problemas com Pedidos' && this.solicitacao.campos.devolucaoTotal == true) {
            if (this.solicitacao.campos.observacoes == null || String(this.solicitacao.campos.observacoes).trim().length < 100) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Mínimo de 100 caracteres para observação!'
               });
               
               return
            }
         }
         
         if (this.solicitacao.campos.tipo != 'Problemas com Pedidos') {
            if (this.solicitacao.campos.notaNumero == null || String(this.solicitacao.campos.notaNumero).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Número NF inválido!'
               });

               return
            } else if (this.solicitacao.campos.notaSerie == null || String(this.solicitacao.campos.notaSerie).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Série NF inválido!'
               });

               return
            } else if (this.solicitacao.campos.dataVenda == null || String(this.solicitacao.campos.dataVenda).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Data da venda inválida!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioNome == null || String(this.solicitacao.campos.proprietarioNome).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Nome proprietário inválido!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioFone == null || String(this.solicitacao.campos.proprietarioFone).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Telefone proprietário inválido!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioCEP == null || String(this.solicitacao.campos.proprietarioCEP).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'CEP proprietário inválido!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioEndereco == null || String(this.solicitacao.campos.proprietarioEndereco).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Endereço proprietário inválido!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioEnderecoNumero == null || String(this.solicitacao.campos.proprietarioEnderecoNumero).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Nº endereço proprietário inválido!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioCidade == null || String(this.solicitacao.campos.proprietarioCidade).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Cidade inválida!'
               });

               return
            } else if (this.solicitacao.campos.proprietarioEstado == null || String(this.solicitacao.campos.proprietarioEstado).trim().length == 0) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Estado inválido!'
               });

               return
            }
         }

         let dataForm = new FormData();
         this.$store.dispatch('alternarTelaCarregamento', true)

         for (let file of this.solicitacao.campos.anexoFotos) {
            dataForm.append('anexoFotos', file, String(file.name).replace(/[^\w\s.]/gi, ''));
         }

         for (let file of this.solicitacao.campos.anexoSeries) {
            dataForm.append('anexoSerie', file, String(file.name).replace(/[^\w\s.]/gi, ''));
         }

         if (this.solicitacao.campos.anexoNF != null) {
            dataForm.append('anexoNF', this.solicitacao.campos.anexoNF, String(this.solicitacao.campos.anexoNF.name).replace(/[^\w\s.]/gi, ''));
         }
         
         if (this.solicitacao.campos.anexoEntrega != null) {
            dataForm.append('anexoEntrega', this.solicitacao.campos.anexoEntrega, String(this.solicitacao.campos.anexoEntrega.name).replace(/[^\w\s.]/gi, ''));
         }

         dataForm.append('info', JSON.stringify(this.solicitacao.campos));

         this.$axios({
            method: 'post',
            url: this.urlRest +'usuario/addSolicitacao',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).then(() => {
            switch (this.solicitacao.campos.tipo) {
               case "Requisição de Garantia":
               case "Garantia Comercial":
                  this.buscarSolicitacoes('Garantias', 0)
               break;
               case "Registro de Entrega Técnica":
                  this.buscarSolicitacoes('Entrega Técnica', 0)
               break;
               case "Registro de Revisão":
                  this.buscarSolicitacoes('Revisões', 0)
               break;
               case "Problemas com Pedidos":
                  this.buscarSolicitacoes('Problemas com Pedido', 0)
               break;
            }

            this.resetCampos(false, 'Requisição de Garantia', true)

            Swal.fire({
               icon: 'success',
               title: 'Solicitação enviada!'

            }).then(() => {
               window.scrollTo(0, 0)
            });
         }).catch((error) => {
            if (error.response != undefined) {
               this.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      getMensagens : function () {
         if (this.chatWebSocket != null) {
            this.$store.dispatch('alternarTelaCarregamento', true)
            this.chatWebSocket.send(JSON.stringify({'tipo': "getMensagens", 'valor': this.solicitacao.chat.mensagens.length }));
         } else {
            this.startWsSolicitacao()
         }
      },
      sendMensagem : function () {
         if (this.solicitacao.chat.anexos.length > 0) {
            this.sendMensagemArquivo()
         } else {
            if (this.chatWebSocket != null) {
               if (String(this.solicitacao.chat.mensagem).trim() != '') {
                  this.chatWebSocket.send(JSON.stringify({'tipo': "sendMensagem", 'valor': String(this.solicitacao.chat.mensagem).replace('\n', ''), 'idUsuario': this.dadosUsuario.cliente.id}));
               }
            } else {
               this.startWsSolicitacao()
            }

            this.solicitacao.chat.mensagem = '';
            this.solicitacao.chat.anexos = [];
         }
      },
      sendMensagemArquivo : function () {
         let dataForm = new FormData();
         dataForm.append('info', JSON.stringify({'id': this.solicitacao.campos.codigo, 'mensagem': this.solicitacao.chat.mensagem}));

         for (let file of this.solicitacao.chat.anexos) {
            dataForm.append('fileChat', file, file.name.replace(/[^\w\s.]/gi, ''));
         }

         let processingMsg = {
            "idUsuario": Math.floor(Math.random() * 9999999999999999),
            "nomeUsuario": "Sistema",
            "mensagem": "Processando arquivo(s)...",
            "data": null,
            "anexos": []
         }

         this.solicitacao.chat.mensagens.push(processingMsg)
         this.solicitacao.chat.mensagem = '';
         this.solicitacao.chat.anexos = [];

         setTimeout(() => {
            $(".chat-conversation").animate({scrollTop: document.querySelector(".chat-conversation").scrollHeight}, 100);
         }, 250);

         this.$axios({
            method: 'post',
            url: this.urlRest +'usuario/sendMensagemArquivo',
            headers: {
					'Content-Type': 'multipart/form-data'
            },
				data: dataForm

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.solicitacao.chat.mensagens.splice(this.solicitacao.chat.mensagens.map(msg => msg.idUsuario).indexOf(processingMsg.idUsuario), 1)
         })
      }
   },
   mounted() {
      this.solicitacao.campos.email = this.dadosUsuario.cliente.email

      this.buscarSolicitacoes('Garantias', 0)
      this.buscarSolicitacoes('Entrega Técnica', 0)
      this.buscarSolicitacoes('Problemas com Pedido', 0)
      this.buscarSolicitacoes('Revisões', 0)
   },
   beforeDestroy() {
      if (this.chatWebSocket != null) {
         this.chatWebSocket.close()
         this.chatWebSocket = null
      }
   }
}

</script>

<style scoped>

#solicitacoes-pills-tabContent .input-group .input-group-text {
   background: #fff;
   border-color: #ebebeb;
   color: #aaa;
   font-size: 13px;
   padding-top: 13px;
   transform: none;
   cursor: pointer;
}

#solicitacoes-pills-tabContent .input-group .form-control {
   border-left: 0;
}

</style>