<template>
   <li>
      <div class="card">
         <div class="card-body">
            <div class="row text-center justify-content-between align-items-center">
               <div class="wpx-75 w-img d-none d-lg-block">
                  <img :src="produto.foto == null ? '' : produto.foto" alt="produto" @error="imageError">
               </div>
               <div class="col-sm mb-3 mb-sm-0 text-start">
                  <h6 class="limitador-2 text-dark font-13">
                     <router-link :to="'/produto/'+ produto.codigo" class="product-item-link">{{ produto.nome }}</router-link>
                  </h6>
                  <div class="cart__desc weight-400 limitador-1 mb-0">
                     <span>Cód. {{ String(produto.codigo).substring(0, 2) +"-"+ String(produto.codigo).substring(2, String(produto.codigo).length) }}</span>
                     <span v-if="produto.tipoProblema != null" class="mx-1">-</span>
                     <span v-if="produto.tipoProblema != null">{{ produto.tipoProblema }}</span>
                     <span v-if="produto.tipoProblema == 'Quantidade Divergente'"> (x{{ produto.quantidade }})</span>
                     <span v-if="produto.codigo2 != null" class="mx-1">-</span>
                     <span v-if="produto.codigo2 != null"> Peça {{ String(produto.codigo2).substring(0, 2) +"-"+ String(produto.codigo2).substring(2, String(produto.codigo2).length) }}</span>
                  </div>
               </div>
               <div class="wpx-125 px-1 mx-auto mx-sm-none" v-if="view == 'Carrinho'">
                  <div class="input-group input-quantity ps-2">
                     <button name="btn-minus" @click.stop="produto.quantidade = produto.quantidade - produto.qtdMult; alterarQuantidade()" :disabled="produto.quantidade <= produto.qtdMult">-</button>
                     <input type="number" v-model="produto.quantidade" @keyup="alterarQuantidade">
                     <button name="btn-plus" @click.stop="produto.quantidade = produto.quantidade + produto.qtdMult; alterarQuantidade()">+</button>
                  </div>
               </div>
               <div class="wpx-125 px-1 d-none d-lg-block mx-auto mx-sm-none">
                  <span><i class="far fa-box font-10 me-1 d-md-none"></i> {{ produto.quantidade == null ? '-' : produto.quantidade }}</span>
               </div>
               <div class="wpx-125 px-1 d-none d-lg-block">
                  <span><small>R$ </small>{{ produto.preco == null ? '-' : parseFloat(produto.preco).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
               </div>
               <div class="wpx-125 px-1 d-none d-xxl-block">
                  <span><small>R$ </small>{{ produto.totalICMS == null ? '-' : parseFloat(produto.totalICMS).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
               </div>
               <div class="wpx-125 px-1 d-none d-xxl-block">
                  <span><small>R$ </small>{{ produto.totalIPI == null ? '-' : parseFloat(produto.totalIPI).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
               </div>
               <div class="wpx-125 px-1 d-sm-none d-md-block mx-auto mx-sm-none">
                  <i class="far fa-money-bill-wave-alt font-10 me-1 d-sm-none"></i>
                  <span> <small>R$ </small>{{ produto.totalComImposto == null ? produto.total == null ? '0,00' : parseFloat(produto.total).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) : parseFloat(produto.totalComImposto).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
               </div>
               <div class="wpx-75 px-1 mx-auto mx-sm-none" v-if="view == 'Carrinho'">
                  <a href="javascript:;" @click="remover"><i class="far fa-trash font-15 text-danger"></i></a>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

export default {
   name: 'Carrinho',
   props: ['produto', 'index', 'view'],
   data : function () {
      return {
         timeout: null
      }
   },
   methods: {
      remover: function () {
         this.$store.dispatch('removeCarrinho', this.index)
      },
      alterarQuantidade : function () {
         clearTimeout(this.timeout);
         
         this.timeout = setTimeout(() => {
            this.produto.quantidade = Math.ceil(this.produto.quantidade / this.produto.qtdMult) * this.produto.qtdMult
            this.$store.dispatch('alterarQuantidadeCarrinho', this.produto)
         }, 500);
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>

<style scoped>

img {
	aspect-ratio: 1 / 1;
	object-fit: cover;
}

.card {
   font-size: 13.5px;
}

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
   color: #444;
}

.card .card-body {
   padding: 8px 12px;
}

.card .w-img img {
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}

</style>