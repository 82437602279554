
<template>
   <li>
      <div class="card">
         <div class="card-body p-2">
            <div class="row m-0 text-center justify-content-center align-items-center font-14">
               <div class="wpx-125 px-1">
                  <span class="limitador">{{ nota.numero == null ? '-' : nota.numero }}</span>
               </div>
               <div class="wpx-100 px-1 d-none d-xl-block">
                  <span class="limitador">{{ nota.serie == null ? '-' : nota.serie }}</span>
               </div>
               <div class="wpx-125 px-1">
                  <span class="limitador">{{ nota.dataEmissao == null ? '-' : nota.dataEmissao.dayOfMonth.toString().padStart(2, '0') +'/'+ nota.dataEmissao.monthValue.toString().padStart(2, '0') +'/'+ nota.dataEmissao.year }}</span>
               </div>
               <div class="wpx-150 px-1 d-none d-lg-block">
                  <span class="limitador">R$ {{ nota.valor == null ? '-' : parseFloat(nota.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
               </div>
               <div class="col px-1 d-none d-xxl-block">
                  <span class="limitador">{{ nota.transportador == null ? '-' : nota.transportador }}</span>
               </div>
               <div class="wpx-175 px-1 text-end mt-2 mt-md-0">
                  <button class="btn bg-theme text-white font-13" @click="devolucaoTotal">
                     <i class="far fa-dolly font-11 me-1"></i> Devolução total
                  </button>
               </div>
               <div class="wpx-75 px-1 text-end mt-2 mt-md-0">
                  <button class="btn bg-theme text-white font-13" @click="select">
                     <i class="far fa-plus"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

export default {
	name: 'Nota',
   props: ['nota'],
   methods: {
      select : function () {
         this.$emit('select', this.nota)
      },
      devolucaoTotal : function () {
         this.$emit('devolucaoTotal', this.nota)
      }
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
   color: #444;
}

</style>