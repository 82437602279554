<template>
   <div class="col-12 mb-10">
      <div class="form-check ps-0">
         <input class="form-check-input" type="checkbox" :id="'permissao'+ index" :checked="permissao.habilitado" v-model="permissao.habilitado">
         <label class="form-check-label" :for="'permissao'+ index">{{ permissao.nome == null ? 'Sem nome' : permissao.nome }}</label>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Permissao',
   props: ['permissao', 'index']
}

</script>

<style scoped>

.form-check-input {
	cursor: pointer;
   height: 20px !important;
   width: 20px;
   padding: 4px !important;
	border-radius: 4px !important;	
   margin-top: 2px !important;
}

.form-check-label {
   cursor: pointer;
	display: block;
	font-size: 12px !important;
	user-select: none;
   padding-left: 30px;
}

</style>