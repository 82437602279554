
<template>
   <li>
      <div class="card">
         <div class="card-body p-2">
            <div class="row m-0 text-xxl-center justify-content-center align-items-center font-13">
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="limitador"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Código:</span> {{ solicitacao.codigo == null ? '-' : solicitacao.codigo }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1">
                  <span class="limitador"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Produto:</span> {{ solicitacao.produto == null ? '-' : solicitacao.produto }}</span>
               </div>
               <div class="col-xxl px-1" v-if="solicitacao.proprietarioNome != null && String(solicitacao.proprietarioNome).trim() != ''">
                  <span class="limitador"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Cliente:</span> {{ solicitacao.proprietarioNome }}</span>
               </div>
               <div class="col-6 wpx-xxl-135 px-1">
                  <span class="limitador">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Abertura:</span>
                     {{ solicitacao.dataAbertura == null ? '-' : solicitacao.dataAbertura.dayOfMonth.toString().padStart(2, '0') +'/'+ solicitacao.dataAbertura.monthValue.toString().padStart(2, '0') +'/'+ solicitacao.dataAbertura.year }}
                  </span>
               </div>
               <div class="col-6 wpx-xxl-135 px-1">
                  <span class="limitador">
                     <span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Alteração:</span>
                     {{ solicitacao.dataAlteracao == null ? '-' : solicitacao.dataAlteracao.dayOfMonth.toString().padStart(2, '0') +'/'+ solicitacao.dataAlteracao.monthValue.toString().padStart(2, '0') +'/'+ solicitacao.dataAlteracao.year }}
                  </span>
               </div>
               <div class="px-1" :class="[solicitacao.status == 'Concedida' ? 'text-green' : solicitacao.status == 'Negada' ? 'text-red' : 'text-yellow', 
                  solicitacao.proprietarioNome != null && String(solicitacao.proprietarioNome).trim() != '' ? 'col-6 wpx-xxl-185' : 'col-xxl']">
                  <span class="limitador"><span class="weight-500 text-uppercase font-10 d-inline d-xxl-none">Status:</span> {{ solicitacao.status == null ? '-' : solicitacao.status }}</span>
               </div>
               <div class="col-6 wpx-xxl-125 px-1 text-xxl-end">
                  <button class="btn bg-theme text-white font-13" @click="visualizar"><i class="far fa-comment-alt font-10 me-1"></i> Visualizar</button>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

export default {
	name: 'Solicitacao',
   props: ['solicitacao'],
   methods: {
      visualizar : function () {
         this.$emit('visualizar', this.solicitacao)
      }
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

.card .card-body {
   padding: 8px 12px;
}

button {
   padding: 4px 12px !important;
}

</style>