
<template>
   <li ref="perfil">
      <div class="card">
         <div class="card-body">
            <div class="row text-center align-items-center font-14">
               <div class="col-md px-1"><span class="limitador"><i class="far fa-user font-10 me-1 d-md-none"></i> {{ perfil.nome }}</span></div>
               <div class="col-sm px-1"><span class="limitador"><i class="far fa-envelope font-10 me-1 d-md-none"></i> {{ perfil.email }}</span></div>
               <div class="wpx-100 px-1 mx-auto mx-sm-0">
                  <button class="btn bg-theme text-white font-13 mt-1 mt-md-0" @click="editar"><i class="fal fa-pen font-10 me-1"></i> Editar</button>
               </div>
               <div class="wpx-100 px-1 mx-auto mx-sm-0">
                  <button class="btn bg-danger text-white font-13 mt-1 mt-md-0" @click="remover"><i class="fal fa-trash font-10 me-1"></i> Excluir</button>
               </div>
            </div>
         </div>
      </div>
   </li>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Perfil',
   props: ['perfil'],
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   methods: {
      editar : function () {
         this.$emit('editar', this.perfil)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Remover perfil',
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar'

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'usuario/removePerfil',
                  params:{
                     id: this.perfil.id
                  }
               }).then(() => {
                  this.$refs.perfil.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Perfil removido!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      }
   }
}

</script>

<style scoped>

.card {
   margin-bottom: 4px;
   border: 1px solid #d6dce1;
   border-radius: 4px;
}

.card .card-body {
   padding: 8px 12px;
}

button {
   padding: 4px 12px !important;
}

</style>